.wrapper {
  position: relative;
  box-sizing: border-box;
  border-left: 1px solid #c2c2c2;
  border-right: 1px solid #c2c2c2;
}

.header {
  display: grid;
  align-content: center;
  border-top: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
  width: 100%;
  background: #f7f7f7;
  column-gap: 15px;
  padding: 10px 10px;
  box-sizing: border-box;
  position: sticky;
  top: 42px;
  /* NOTE: just enough to be above the nav shadow */
  z-index: 9;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #c2c2c2;
  border-bottom: 1px solid #c2c2c2;
  width: 100%;
  background: #f7f7f7;
  padding: 10px 10px;
  box-sizing: border-box;
  position: sticky;
  bottom: 0;
  /* NOTE: just enough to be above the nav shadow */
  z-index: 9;
}

.limitSelect {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 10px;
}

.row {
  display: grid;
  align-items: flex-start;
  column-gap: 15px;
  border-bottom: 1px solid #c2c2c2;
  padding: 16px 10px;
  max-width: 100%;
  overflow: hidden;
  background: white;
  overflow-wrap: break-word;
}

.rowAlternating:nth-child(even) {
  background: #fcfcfc;
}

.rowChunk:last-of-type .row:last-of-type {
  border-bottom: none;
}
